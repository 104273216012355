/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "e30c4f7cc78d4170907e597084563adf",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "e30c4f7cc78d4170907e597084563adf",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://lvdixdxvs5gw7o5qar3af7ojba.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-4h5fz6lzujavfe2gzo2s32nbd4",
    "aws_cloud_logic_custom": [
        {
            "name": "relampagoApi",
            "endpoint": "https://mrru67yhol.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:bca20edd-1cab-426a-a3fa-29af623e4a6c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ww2161rAu",
    "aws_user_pools_web_client_id": "3fhvgf8n182iu70o1tatk6eshq",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "relampago-storage-a193c57f234046-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
