import '../css/WebsiteHeader.css';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { BLACK_COLOR, Home } from "./Home";
import React from 'react';
import PrivacyPolicy from './PrivacyPolicy';
import Form from './Form.tsx';
import Download from './Download.tsx';
import API from './API.tsx';
import FAQ from './FAQ.tsx';
import { Analytics } from 'aws-amplify';
import { Helmet } from 'react-helmet';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});
const origin = params["origin"] || "none";

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

type Props = {
    location: Location,
};

class App extends React.Component<Props> {

    async componentDidMount() {
        await delay(500);
        if (!window.location.host.includes("localhost")) {
            Analytics.record({ name: 'relampago_website_visit', attributes: { origin } });
        }
    }

    render() {
        const { location } = this.props;
        const lang = location.pathname.includes("en") ? "en" : "es";

        return (
            <div style={{ verticalAlign: 'top', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {lang === "en" ?
                    <Helmet>
                        <link rel="canonical" href="https://relampago.cash/en" />
                        <meta
                            name="og:title"
                            content="Relampago - Pagos baratos al instante en Colombia"
                        />
                        <meta
                            name="description"
                            content="Disfrute de los pagos más baratos, rápidos y fiables a Colombia. Con unos pocos toques, realice un pago al instante y vea cómo llega a la cuenta bancaria de su destinatario en cuestión de segundos."
                        />
                        <meta name="keywords" content="remesas, pagos, transferencias, giros, envíos, Colombia" />
                        <meta name="twitter:title" content="Relampago - Pagos baratos al instante en Colombia" />
                        <meta name="twitter:description" content="Disfrute de los pagos más baratos, rápidos y fiables a Colombia. Con unos pocos toques, realice un pago al instante y vea cómo llega a la cuenta bancaria de su destinatario en cuestión de segundos." />
                        <meta http-equiv="content-language" content="es" />
                        <meta name="geo.region" content="CO" />
                        <meta name="geo.placename" content="Colombia" />
                    </Helmet> :
                    <Helmet>
                        <link rel="canonical" href="https://relampago.cash" />
                        <meta
                            name="og:title"
                            content="Relampago - Cheap and instant payments to Colombia"
                        />
                        <meta
                            name="description"
                            content="Enjoy the cheapest, fastest and most secure payments to Colombia. With a few taps, send a payment instantly to any Colombian bank account."
                        />
                        <meta name="keywords" content="Colombia, USA, payments, remittances" />
                        <meta name="twitter:title" content="Relampago - Cheap and instant payments to Colombia" />
                        <meta name="twitter:description" content="Enjoy the cheapest, fastest and most secure payments to Colombia. With a few taps, send a payment instantly to any Colombian bank account." />
                        <meta http-equiv="content-language" content="en" />
                        <meta name="geo.region" content="US" />
                        <meta name="geo.placename" content="United States" />
                    </Helmet>
                }
                <div className='top-container' style={{ zIndex: 1, position: 'relative', paddingTop: 0, flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                    {!location.pathname.includes("download") && <div style={{ maxWidth: 1500, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                        <div className='top-header' style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: 'white',
                            margin: 'auto',
                            width: '100%',
                            paddingLeft: 100,
                            alignItems: 'center',
                            marginBottom: 20,
                        }}>
                            <div style={{ display: 'flex' }}>
                                {/* <a className='header-item' href='/' style={{ marginLeft: 50 }}><p>Inicio</p></a> */}
                                {/* <a className='header-item' href='https://docs-beta.relampago.cash' style={{ marginLeft: 50 }}>Documentación</a> */}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Link to={`/${lang}/#`} style={{ textDecoration: 'none' }}>
                                    <p className='underline-hover' style={{ color: BLACK_COLOR, marginBottom: 0, marginRight: 30, fontSize: 20 }}>{lang === 'en' ? 'Home' : 'Inicio'}</p>
                                </Link>
                                <Link to={`/${lang}#contactus`} style={{ textDecoration: 'none' }}>
                                    <p className='underline-hover' style={{ color: BLACK_COLOR, marginBottom: 0, marginRight: 30, fontSize: 20 }}>{lang === 'en' ? 'Contact us' : 'Contactarnos'}</p>
                                </Link>
                                <Link to={"apis"} style={{ textDecoration: 'none' }}>
                                    <p className='underline-hover' style={{ color: BLACK_COLOR, marginBottom: 0, marginRight: 30, fontSize: 20 }}>{lang === 'en' ? 'API Docs' : 'API Docs'}</p>
                                </Link>
                                <Link to={`${lang}/faq`} style={{ textDecoration: 'none' }}>
                                    <p className='underline-hover' style={{ color: BLACK_COLOR, marginBottom: 0, marginRight: 50, fontSize: 20 }}>{lang === 'en' ? 'FAQ' : 'FAQ'}</p>
                                </Link>
                                <Link to={`en/${location.pathname.split('/')[2] || ''}`} style={{ textDecoration: 'none' }}>
                                    <div className={`underline-hover language-button ${lang === 'en' ? 'language-button-enabled' : 'language-button-disabled'}`} style={{ marginRight: 10 }}>
                                        <p style={{ fontSize: 16, marginBottom: 0, color: 'white' }}>{"EN"}</p>
                                    </div>
                                </Link>
                                <Link to={`es/${location.pathname.split('/')[2] || ''}`} style={{ textDecoration: 'none', }}>
                                    <div className={`underline-hover language-button ${lang === 'es' ? 'language-button-enabled' : 'language-button-disabled'}`}>
                                        <p style={{ fontSize: 16, marginBottom: 0, color: 'white' }}>{"ES"}</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>}
                    <Routes>
                        <Route exact path='/' element={<Home lang={'es'} />}>
                        </Route>
                        <Route exact path='/:lang/' element={<>
                            <Home lang={lang} />
                        </>}></Route>
                        <Route exact path='/privacy-policy' element={<PrivacyPolicy />}></Route>
                        <Route exact path='/unirte' element={<Form />}></Route>
                        <Route exact path='/download' element={<Download />}></Route>
                        <Route exact path='/apis' element={<API />}></Route>
                        <Route exact path='/:lang/faq/' element={<FAQ lang={lang} />}></Route>
                    </Routes>
                </div>
            </div >
        );
    }
}

const WrappedApp = () => {
    return (
        <Router>
            <RoutedApp />
        </Router>
    )
}

const RoutedApp = () => {
    const location = useLocation();

    return (
        <App location={location} />
    )
}

export default WrappedApp;