
import React from 'react';
import 'react-phone-input-2/lib/style.css'

export const BUTTON_COLOR = '#75AAFA';
export const BLACK_COLOR = '#0C1618';
export const GREY_COLOR = '#828282';
export const SUBTITLE_COLOR = 'rgba(0, 0, 0, 0.35)';
export const PADDING = 10;
export const RED_COLOR = '#EE4B2B';

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

type Props = {
    lang: 'en' | 'es';
};


class FAQ extends React.Component<Props> {
    render() {
        const { lang } = this.props;

        const faqContent = {
            en: {
                title: 'Frequently Asked Questions - Relampago',
                questions: [
                    {
                        q: 'What is Relampago?',
                        a: 'Relampago is an instant payment app facilitating money transfers from the USA to Colombia. It requires users to complete a Know Your Customer (KYC) process and connect a bank account for transactions.'
                    },
                    {
                        q: 'How do I send money using Relampago?',
                        a: 'You can send money by searching for the receiver in-app using their name, phone number, or alias. Alternatively, you can manually enter the banking details of the receiver.'
                    },
                    {
                        q: 'What if I don’t have the Relampago app but need to send money?',
                        a: 'If you’re an American user with Cash App, you can scan a Relampago-generated QR code to send money to the recipient in Colombia.'
                    },
                    {
                        q: 'Is there a limit to how much I can send?',
                        a: 'Yes, senders can transfer up to $3,000 per day.'
                    },
                    {
                        q: 'What are the requirements for recipients in Colombia?',
                        a: 'Recipients must connect their bank account to receive money. Unverified users can receive up to $1,000 per month. Verified users have no monthly receiving limit.'
                    },
                    {
                        q: 'What charges apply to transactions?',
                        a: 'We charge a 1% fee on all transactions, with no hidden costs. The exchange rate used is the exact current rate.'
                    },
                    {
                        q: 'Are there any bonuses for first-time transactions?',
                        a: 'Yes, on a sender\'s first transaction to a new receiver, the receiver gets an additional 100,000 COP. First-time receivers from Cash App users also receive an extra 100,000 COP.'
                    },
                    {
                        q: 'What are referral benefits?',
                        a: 'Every receiver has a referral code. Using this code gives the receiver an additional 20,000 COP and the referrer 20,000 COP.'
                    },
                    {
                        q: 'How does Relampago ensure privacy?',
                        a: 'Users can set aliases to maintain privacy. Profile pictures are also allowed for a personalized experience.'
                    }
                ]
            },
            es: {
                title: 'Preguntas Frecuentes - Relampago',
                questions: [
                    {
                        q: '¿Qué es Relampago?',
                        a: 'Relampago es una aplicación de pagos instantáneos que facilita las transferencias de dinero de EE.UU. a Colombia. Requiere que los usuarios completen un proceso de Conozca a Su Cliente (KYC) y conecten una cuenta bancaria para las transacciones.'
                    },
                    {
                        q: '¿Cómo envío dinero usando Relampago?',
                        a: 'Puede enviar dinero buscando al receptor en la aplicación usando su nombre, número de teléfono o alias. Alternativamente, puede ingresar manualmente los detalles bancarios del receptor.'
                    },
                    {
                        q: '¿Qué pasa si no tengo la aplicación Relampago pero necesito enviar dinero?',
                        a: 'Si es un usuario estadounidense con Cash App, puede escanear un código QR generado por Relampago para enviar dinero al destinatario en Colombia.'
                    },
                    {
                        q: '¿Hay un límite para la cantidad que puedo enviar?',
                        a: 'Sí, los remitentes pueden transferir hasta $3,000 por día.'
                    },
                    {
                        q: '¿Cuáles son los requisitos para los destinatarios en Colombia?',
                        a: 'Los destinatarios deben conectar su cuenta bancaria para recibir dinero. Los usuarios no verificados pueden recibir hasta $1,000 por mes. Los usuarios verificados no tienen límite mensual de recepción.'
                    },
                    {
                        q: '¿Qué cargos se aplican a las transacciones?',
                        a: 'Cobramos una comisión del 1% en todas las transacciones, sin costos ocultos. El tipo de cambio utilizado es la tasa actual exacta.'
                    },
                    {
                        q: '¿Hay bonificaciones por transacciones por primera vez?',
                        a: 'Sí, en la primera transacción de un remitente a un nuevo receptor, el receptor recibe 100,000 COP adicionales. Los receptores que reciben por primera vez de usuarios de Cash App también reciben 100,000 COP adicionales.'
                    },
                    {
                        q: '¿Cuáles son los beneficios de referencias?',
                        a: 'Cada receptor tiene un código de referencia. Usar este código da al receptor 20,000 COP adicionales y al referente 20,000 COP.'
                    },
                    {
                        q: '¿Cómo asegura Relampago la privacidad?',
                        a: 'Los usuarios pueden establecer alias para mantener la privacidad. También se permiten fotos de perfil para una experiencia personalizada.'
                    }
                ]
            }
        };


        return (
            <div style={{ maxWidth: 1500, marginTop: 50, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', paddingLeft: 50, paddingRight: 50 }}>
                <h1 style={{ marginBottom: 50, }}>{faqContent[lang].title}</h1>
                <ul>
                    {faqContent[lang].questions.map((item, index) => (
                        <li style={{ marginTop: 20, marginBottom: 30 }} key={index}>
                            <div style={{ marginBottom: 5 }}><strong style={{ fontFamily: 'Visby-Heavy' }}>{item.q}</strong></div>
                            <p>{item.a}</p>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default FAQ;

