import React from 'react';
import { Analytics } from 'aws-amplify';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});
const origin = params["origin"] || "none";

const Form = () => {

    function redirectToAppStore() {
        const fallbackUrl = 'https://relampago.cash';

        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Detect iOS devices
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.href = "https://apps.apple.com/us/app/rel%C3%A1mpago/id6449205587";
            return;
        }

        window.location.href = "https://play.google.com/store/apps/details?id=com.cash.relampago";

        // Set up a timeout to check whether the redirection succeeded
        const timeout = setTimeout(() => {
            // If the custom scheme URL is not valid, the user will still be on the same page
            // Redirect to the fallback URL
            window.location = fallbackUrl;
        }, 1000);

        // Clear the timeout if the user navigates away from the page
        window.addEventListener('blur', () => clearTimeout(timeout));
    }

    const runItems = async () => {
        Analytics.record({ name: 'relampago_download_visit', attributes: { origin } });
        redirectToAppStore();
    }

    React.useEffect(() => {
        runItems();
    });

    return (<div></div>
    );
}

export default Form;

/* import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import '../css/WebsiteHeader.css';
import '../css/Home.css';
import { ReactComponent as Icon } from '../svg/relampago-smaller.svg';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { API, Analytics, Auth, Hub } from 'aws-amplify';
import { ReactComponent as LoadingIcon } from '../svg/Inflight-icon.svg';
import es from 'react-phone-input-2/lang/es.json'
import _ from "lodash";

type Props = {};

type State = {
    started: boolean,
    signingIn: boolean,
    phoneVerified: boolean,
    phoneNumber: string,
    verificationCode: string,
    phoneNumberUsed: string,
    codeSending: boolean,
    codeSent: boolean,
    codeVerifying: boolean,
    codeVerified: boolean,
    bankLabels: BankLabels,
    // form
    chosenBank: string,
    chosenBankCode: string,
    firstName: string,
    lastName: string,
    accountNumber: string,
    accountType: string,
    dateOfBirth: string,
    dateFocused: boolean,
    nationalId: string,
    verificationSubmitted: boolean,
    verificationSubmitting: boolean,
    waiting: boolean,
    verified: boolean,
    verificationFailed: boolean,
}
type Banks = { name: string, countryCode: string, code: string }[];
type BankLabels = { label: string, value: string }[];
type BankAccount = {
    id?: string,
    institution_name: string,
    institution_code: string,
    country?: string,
    verification_status?: string,
    account_id?: string,
    account_type?: string,
    account_name?: string,
    account_number?: string,
    clabe?: string
    debit_card?: string
    phone_number?: string
    bank_verification_id?: string
}


export const BUTTON_COLOR = '#75AAFA';
export const BLACK_COLOR = '#0C1618';
export const GREY_COLOR = '#828282';
export const SUBTITLE_COLOR = 'rgba(0, 0, 0, 0.35)';
export const PADDING = 10;
export const RED_COLOR = '#EE4B2B';

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

let hub: any;

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});
const origin = params["origin"] || "none";


class FormClass extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            started: false,
            signingIn: false,
            phoneVerified: false,
            codeSent: false,
            codeSending: false,
            phoneNumber: "",
            verificationCode: "",
            phoneNumberUsed: "",
            codeVerifying: false,
            codeVerified: false,
            bankLabels: [],
            chosenBank: "",
            chosenBankCode: "",
            firstName: "",
            lastName: "",
            accountNumber: "",
            accountType: "Ahorros",
            dateOfBirth: "",
            dateFocused: false,
            nationalId: "",
            verificationSubmitted: false,
            verificationSubmitting: false,
            waiting: false,
            verified: false,
            verificationFailed: false,
        };
    }

    componentWillUnmount(): void {
        hub();
    }

    async componentDidMount() {

        // Record clickthrough from ad.
        Analytics.record({ name: 'webFormVisitColombia', attributes: { origin } });

        hub = Hub.listen('auth', async (data) => {
            const event = data.payload.event;

            switch (event) {
                case 'signIn':
                    this.setState({ phoneVerified: true, started: true });
                    this.getBankLabels();
                    break;
                default:
                    break;
            }
        });

        try {
            const user = await Auth.currentAuthenticatedUser({
                bypassCache: true
            });
            await this.getBankAccounts(user["username"]);
            this.setState({ phoneVerified: true, started: true });
            this.getBankLabels();
        } catch (e) {
            // phone not verified.
        }
    }

    async getBankLabels() {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        const result = await API.post('relampagoApi', '/getBanks', {
            headers: {
                Authorization: `${token}`
            },
            body: {
                'country_code': 'CO',
            }
        }) as { banks: Banks };
        const bankLabels: BankLabels = result.banks.map(b => ({
            label: b.name,
            value: `${b.name}-${b.code}`
        })).sort((a, b) => a.label.localeCompare(b.label));
        const specialBanks = bankLabels.filter(b => ["Nequi", "Movii", "Daviplata"].includes(b.label));
        const otherBanks = bankLabels.filter(b => !["Nequi", "Movii", "Daviplata"].includes(b.label));
        const finalBankLabels = specialBanks.concat(otherBanks);
        this.setState({ bankLabels: finalBankLabels });

    }

    async getBankAccounts(username: string) {
        const bankAccountsResponse = await API.graphql({ query: bankAccountsByOwner, variables: { owner: username } });
        const bankAccounts = _.get(bankAccountsResponse, "data.bankAccountsByOwner.items") || [] as BankAccount[];
        let pending = true;
        let failed = true;
        for (const account of bankAccounts) {
            if (account.verification_status === "SUCCESS") {
                this.setState({ verified: true });
                return;
            }
            if (account.verification_status !== "PENDING") {
                pending = false;
            }
            if (account.verification_status !== "FAILED") {
                failed = false;
            }
        }
        if (bankAccounts.length > 0 && pending) {
            this.setState({ waiting: true });
            this.verify();
        } else if (bankAccounts.length > 0 && failed) {
            this.setState({ verificationFailed: true });
        }
    }

    async verify() {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        while (true) {
            try {
                const data = await API.post(
                    'relampagoApi',
                    '/checkLatamVerification',
                    {
                        headers: {
                            Authorization: `${token}`
                        },
                    }
                );
                console.log("Poll verification result: ", data);
                if (data) {
                    this.setState({ verified: true, waiting: false });
                    break;
                }
            } catch (e) {
                if (e.message !== "Network Error") {
                    this.setState({ verificationFailed: true, waiting: false });
                    break;
                }
            }
            await delay(5000);
        }
    }

    getHeader() {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderBottom: `1px solid #ddd` }} className='mb-3'>
                <Icon style={{ width: 30, height: 30 }} />
                <p style={{ fontSize: 20, marginLeft: 10, paddingTop: 18, color: BLACK_COLOR, fontWeight: 'bold' }}>Regístrarse en Relampago </p>
            </div>
        )
    }

    getWaiting() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100%' }}>
                <div className='mb-5' style={{ zIndex: 1, marginTop: 100, display: 'flex', width: 100, height: 100, backgroundColor: BUTTON_COLOR, borderRadius: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingIcon style={{ zIndex: 2 }} />
                    <Spinner animation="grow" style={{ zIndex: 0, position: 'absolute', width: 150, height: 150, backgroundColor: BUTTON_COLOR }}></Spinner>
                </div>
                <p className='mb-5' style={{ textAlign: 'center', color: GREY_COLOR, fontSize: 15, paddingLeft: PADDING, paddingRight: PADDING }}>Sus datos están siendo verificados. Esto puede tardar unos minutos...</p>
            </div>
        );
    }


    getWaitingStub() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <div className='mb-5' style={{ zIndex: 1, marginTop: 100, display: 'flex', width: 100, height: 100, backgroundColor: BUTTON_COLOR, borderRadius: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingIcon style={{ zIndex: 2 }} />
                </div>
                <p className='mb-2' style={{ textAlign: 'center', color: GREY_COLOR, fontSize: 15, paddingLeft: PADDING, paddingRight: PADDING }}>Sus datos están siendo verificados. Debería recibir un mensaje de WhatsApp con todos los detalles en unos minutos.</p>
                {isMobile && <Button onClick={() => { window.location.href = 'https://wa.me/+14156389425'; }} className={'custom-button'} style={{ width: '75%', opacity: 1, fontWeight: 'bold' }}
                    variant="primary" type="submit">
                    {"Ir a WhatsApp"}
                </Button>}
            </div>
        );
    }

    getVerified() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <div className='mb-5' style={{ zIndex: 1, marginTop: 100, display: 'flex', width: 100, height: 100, backgroundColor: BUTTON_COLOR, borderRadius: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingIcon style={{ zIndex: 2 }} />
                </div>
                <p className='mb-2' style={{ textAlign: 'center', color: GREY_COLOR, fontSize: 15, paddingLeft: PADDING, paddingRight: PADDING }}>Tus datos han sido verificados. Deberías recibir un mensaje de WhatsApp con todos los detalles.</p>
                {isMobile && <Button onClick={() => { window.location.href = 'https://wa.me/+14156389425'; }} className={'custom-button'} style={{ width: '75%', opacity: 1, fontWeight: 'bold' }}
                    variant="primary" type="submit">
                    {"Ir a WhatsApp"}
                </Button>}
            </div>
        );
    }

    getNotVerified() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100%' }}>
                <div className='mb-5' style={{ zIndex: 1, marginTop: 100, display: 'flex', width: 100, height: 100, backgroundColor: RED_COLOR, borderRadius: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingIcon style={{ zIndex: 2 }} />
                </div>
                <p className='mb-2' style={{ textAlign: 'center', color: GREY_COLOR, fontSize: 15 }}>No hemos podido verificar sus datos.</p>
                <Button onClick={() => this.setState({ verificationFailed: false })} className={'custom-button'} style={{ width: '75%', opacity: 1, fontWeight: 'bold' }}
                    variant="primary" type="submit">
                    {"Inténtalo de nuevo."}
                </Button>
            </div>
        );
    }

    getVerifyPhone() {

        const { phoneNumber, verificationCode, codeSent, codeVerifying, codeVerified, codeSending } = this.state;
        const phoneNumberInvalid = !phoneNumber || phoneNumber.length < 12;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p className='mb-3' style={{ textAlign: 'center', color: GREY_COLOR, fontSize: 15 }}>Introduce su número de teléfono.</p>
                <Form className='justify-content-center' onSubmit={async (e) => {
                    e.preventDefault();
                    this.setState({ codeSending: true });
                    try {
                        const result = await API.post(
                            'relampagoApi',
                            '/sendCode',
                            {
                                body: {
                                    phone_number: '+' + phoneNumber,
                                    signing_up: true,
                                }
                            }
                        );
                        this.setState({ phoneNumberUsed: result });
                    } catch (e) {
                        this.setState({ signingIn: true });
                        const result = await API.post(
                            'relampagoApi',
                            '/sendCode',
                            {
                                body: {
                                    phone_number: '+' + phoneNumber,
                                    signing_up: false,
                                }
                            }
                        );
                        this.setState({ phoneNumberUsed: result });
                    }

                    this.setState({ codeSent: true, codeSending: false });
                }}>
                    <Form.Group className='d-flex flex-column align-items-center mb-5'>
                        <PhoneInput
                            country={'co'}
                            onlyCountries={['co']}
                            localization={es}
                            disableDropdown={true}
                            placeholder='1234567890'
                            countryCodeEditable={false}
                            value={phoneNumber}
                            onFocus={(e) => setTimeout(() => { e.preventDefault(); window.scrollTo(0, 1); }, 1)}
                            onBlur={() => window.scrollTo(0, 0)}
                            buttonStyle={{ borderBottomLeftRadius: 0 }}
                            inputStyle={{ width: '100%', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                            containerStyle={{ width: '75%', marginBottom: ".5rem", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                            onChange={phone => this.setState({ phoneNumber: phone })}
                        />
                        <Button disabled={phoneNumberInvalid}
                            className={codeSent ? 'custom-button-dark' : 'custom-button'}
                            style={{ width: '75%', borderTopLeftRadius: 0, borderTopRightRadius: 0, opacity: !phoneNumberInvalid ? 1 : .5, fontWeight: !phoneNumberInvalid ? 'bold' : 'normal' }}
                            variant="primary" type="submit">
                            {codeSending ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : codeSent ? "✅ Código enviado" : "Enviar código"}
                        </Button>
                    </Form.Group>
                </Form>
                {codeSent && <>
                    <p className='mb-3' style={{ textAlign: 'center', color: GREY_COLOR, fontSize: 15 }}>Verifica el código enviado por WhatsApp.</p>
                    <Form onSubmit={async (e) => {
                        e.preventDefault();
                        window.scrollTo(0, 1);
                        Analytics.record({ name: 'webFormVisitColombia_verifyingPhone', attributes: { origin } });
                        this.setState({ codeVerifying: true });
                        const result = await API.post(
                            'relampagoApi',
                            '/verifyCode',
                            {
                                body: {
                                    phone_number: this.state.phoneNumberUsed,
                                    signing_up: !this.state.signingIn,
                                    code: verificationCode,
                                    country: 'CO'
                                }
                            }
                        ) as string;
                        const [username, password] = result.split(',');
                        this.setState({ codeVerifying: false, codeVerified: true });
                        await Auth.signIn({ username, password });
                        window.scrollTo(0, 1);
                        this.getBankAccounts(username);
                    }}>
                        <Form.Group className='d-flex flex-column align-items-center mb-4'>
                            <Form.Control
                                type="tel"
                                style={{ textAlign: 'center', width: '75%', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, border: '1px solid #CACACA', fontSize: 14 }}
                                value={verificationCode}
                                className='mb-2'
                                onChange={e => this.setState({ verificationCode: e.target.value })}
                                placeholder="Introducir código de verificación"
                            />
                            <Button className={codeVerified ? 'custom-button-dark' : 'custom-button'} style={{ width: '75%', borderTopLeftRadius: 0, borderTopRightRadius: 0, opacity: verificationCode ? 1 : .5, fontWeight: verificationCode ? 'bold' : 'normal' }}
                                disabled={!verificationCode} variant="primary" type="submit" id='asdasd'>
                                {codeVerifying ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : codeVerified ? "✅ Código verificado" : "Verificar código"}
                            </Button>
                        </Form.Group>
                    </Form>
                </>}
            </div>
        );
    }

    getSignupForm() {
        const { bankLabels, verificationSubmitted, verificationSubmitting, firstName, lastName, chosenBank, chosenBankCode, nationalId, dateOfBirth, accountNumber, accountType, dateFocused } = this.state;

        const useAccountNumber = true; // chosenBank !== "Nequi";
        const ready = Boolean(firstName && lastName && chosenBank && chosenBankCode && nationalId && dateOfBirth && accountType && dateFocused && (!useAccountNumber || Boolean(accountNumber)));
        return (
            <div className='verify-latam' style={{ paddingLeft: PADDING, paddingRight: PADDING }}>
                <p className='mb-4' style={{ textAlign: 'left', color: GREY_COLOR, fontSize: 15 }}>Proporcione la información de pago para empezar a recibir pagos instantáneos.</p>
                <Form onSubmit={async (e) => {
                    e.preventDefault();
                    Analytics.record({ name: 'webFormVisitColombia_signedUp', attributes: { origin } });
                    window.scrollTo(0, 1);
                    this.setState({ verificationSubmitting: true });
                    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
                    const body = {
                        'bank_name': chosenBank || '',
                        'first_name': firstName,
                        'last_name': lastName,
                        'bank_code': chosenBankCode?.split('-')[1] || '',
                        'clabe': "",
                        'account_number': accountNumber || "",
                        'account_type': accountType === 'Ahorros' ? 'Savings' : 'Checking',
                        'document_type': 'National Id',
                        'document_id': nationalId || '',
                        'debit_card': "",
                        'date_of_birth': dateOfBirth,
                    };
                    console.log(body);
                    const response = await API.post('relampagoApi', '/verifyLatamBank', {
                        body,
                        headers: {
                            Authorization: `${token}`
                        }
                    }) as string;
                    console.log(response);
                    this.setState({ verificationSubmitting: false, verificationSubmitted: true });
                    await delay(1000);
                    this.setState({ waiting: true });
                    this.verify();
                }}>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridEmail" >
                            <Form.Label className='mb-0'>Nombre</Form.Label>
                            <Form.Control
                                onFocus={(e) => {
                                    e.preventDefault();
                                    window.scrollBy(0, 0)
                                    setTimeout(() => {
                                        e.preventDefault();
                                        window.scrollTo(0, 1);
                                    }, 1);
                                }}
                                onChange={e => this.setState({ firstName: e.target.value })}
                                placeholder="Introducir nombre" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridPassword" style={{ paddingLeft: 0 }}>
                            <Form.Label className='mb-0'>Apellido</Form.Label>
                            <Form.Control
                                onFocus={(e) => {
                                    setTimeout(() => {
                                        e.preventDefault();
                                        window.scrollTo(0, 1);
                                    }, 1);
                                }}
                                onChange={e => this.setState({ lastName: e.target.value })}
                                placeholder="Introducir apellido" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label className='mb-0'>Cedula</Form.Label>
                            <Form.Control
                                onChange={e => this.setState({ nationalId: e.target.value })}
                                type="number" placeholder="123..." />
                        </Form.Group>
                        <Form.Group as={Col} style={{ paddingLeft: 0 }}>
                            <Form.Label className='mb-0'>Fecha de nacimiento</Form.Label>
                            <Form.Control style={{ color: dateFocused ? BLACK_COLOR : "#CACACA", display: 'flex', flexDirection: 'row' }} defaultValue={"2000-01-01"} onFocus={() => this.setState({ dateFocused: true })} onChange={(e) => this.setState({ dateOfBirth: e.target.value })} type="date" />
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3">
                        <Form.Label className='mb-0'>Nombre del banco</Form.Label>
                        <Form.Select style={{ color: !chosenBankCode ? "#CACACA" : BLACK_COLOR }} onChange={(e) => this.setState({ chosenBank: e.target.value, chosenBankCode: bankLabels.find(i => i.label === e.target.value)?.value || "" })} className='form-control'>
                            {[{ label: "Select bank..." }, ...bankLabels].map((item) => {
                                return (
                                    <option key={item.label}>{item.label}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    {useAccountNumber && <Form.Group className="mb-3" as={Col}>
                        <Form.Label className='mb-0'>Número de cuenta</Form.Label>
                        <Form.Control
                            onChange={e => this.setState({ accountNumber: e.target.value })}
                            type="numeric" placeholder="123..." />
                    </Form.Group>}
                    {<Form.Group className="mb-3" as={Col}>
                        <Form.Label className='mb-0'>Tipo de cuenta</Form.Label>
                        <Form.Select onChange={(e) => this.setState({ accountType: e.target.value })} className='form-control' defaultValue={accountType}>
                            <option>Ahorros</option>
                            <option>Comprobación</option>
                        </Form.Select>
                    </Form.Group>}
                    <Form.Group className="mt-4 mb-2 d-flex flex-column align-items-center">
                        <Button disabled={!ready} className={verificationSubmitted ? 'custom-button-dark' : 'custom-button'} style={{ width: '75%', opacity: ready ? 1 : .5, fontWeight: ready ? 'bold' : 'normal' }}
                            variant="primary" type="submit">
                            {verificationSubmitting ? <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : verificationSubmitted ? "✅ Detalles enviados" : "Enviar detalles"}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        );
    }

    getBeginning() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Icon className="mb-4" style={{ width: 80, height: 80, marginTop: 100 }}></Icon>
                <p className='mb-2' style={{ textAlign: 'center', color: GREY_COLOR, fontSize: 15, fontWeight: 'bold' }}>¡Bienvenido a Relámpago!</p>
                <p className='mb-3' style={{ textAlign: 'center', color: GREY_COLOR, fontSize: 15, marginLeft: PADDING * 2, marginRight: PADDING * 2 }}>
                    Disfrute de pagos fáciles e instantáneos. Rellene este formulario para crear su cuenta y reciba dinero al instante de cualquier persona en Estados Unidos.
                </p>
                <Button onClick={() => this.setState({ started: true })} className={'custom-button'} style={{ width: '75%', opacity: 1, fontWeight: 'bold' }}
                    variant="primary" type="submit">
                    {"Empezar"}
                </Button>
            </div>
        )
    }

    render() {
        const { phoneVerified, waiting, verificationFailed, verified, started } = this.state;
        const isIOS = getMobileOperatingSystem() === "iOS";
        return (
            <Container className='d-flex flex-column' style={{ minHeight: isIOS ? '100dvh' : '100vh', maxWidth: 500, paddingBottom: isIOS ? 0 : 50 }}>
                {started && this.getHeader()}
                {verificationFailed ? this.getNotVerified()
                    : verified ? this.getVerified()
                        : waiting ? this.getWaitingStub() // this.getWaiting()
                            : phoneVerified ? this.getSignupForm()
                                : started ? this.getVerifyPhone() : this.getBeginning()}
                <div style={{ flexGrow: 1 }}></div>
                <p style={{ textAlign: 'center', color: GREY_COLOR }}>{!started ? "1" : phoneVerified ? "3" : "2"} / 3</p>
            </Container>
        );
    }

}
export default FormClass;

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}


const bankAccountsByOwner = `
  query BankAccountsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBankAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bankAccountsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        institution_name
        institution_code
        access_token
        country
        verification_status
        account_name
        account_number
        timestamp
        account_id
        account_type
        clabe
        debit_card
        phone_number
        bank_verification_id
        cybrid_external_bank_guid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`; */