import ReactDOM from 'react-dom/client';

import './css/index.css';
import App from './js/App';

import { Amplify, Analytics } from 'aws-amplify';
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

Analytics.autoTrack('session', {
    enable: true,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);
