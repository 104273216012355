
import { ReactComponent as Frame } from '../svg/frame.svg';
import vid from '../videos/newVid.mov';
import React, { useEffect, useState } from 'react';
import Qr from '../images/qr.png';

import Earth from '../images/earth.webp';
import Vault from '../images/vault.webp';
import Shield from '../images/shield.webp';
import Rocket from '../images/rocket.webp';
import Plaid from '../images/plaid_grey.webp';
import Nequi from '../images/nequi_grey.webp';
import BancoColombia from '../images/bancocolombia_grey.webp';
import BancoColombiaMobile from '../images/mobile/bancocolombia_grey.webp';
import PlaidMobile from '../images/mobile/plaid_grey.webp';
import NequiMobile from '../images/mobile/nequi_grey.webp';
import GoogleBadge from '../images/googleBadge.webp';
import SenderScreenshot from '../images/sender_screenshot.webp';
import CashappScreenshot from '../images/cashapp_screenshot.webp';
import CashappScreenshotMobile from '../images/mobile/cashapp_screenshot.webp';
import SenderScreenshotMobile from '../images/mobile/sender_screenshot.webp';

import { ReactComponent as Logo } from '../svg/relampago-smaller.svg';
import Colombia from '../images/colombia.png';
import { ReactComponent as Whatsapp } from '../svg/whatsapp.svg';
import { ReactComponent as AppleDownload } from '../svg/appleDownload.svg';
import { ReactComponent as Money } from '../svg/money.svg';
import { ReactComponent as Instagram } from '../svg/instagram.svg';
import { ReactComponent as Email } from '../svg/email.svg';
import { Link, useLocation } from 'react-router-dom';

export const BUTTON_COLOR = '#75AAFA';
export const BLACK_COLOR = '#0C1618';
export const GREY_COLOR = '#828282';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});
const origin = params["origin"] || "";


export const Home = ({ lang }) => {
    const location = useLocation();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const mobile = windowWidth <= 800;

    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    });

    return (
        <>
            <div style={{ maxWidth: 1500, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                <div className='mobile-only' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                    <Logo className='mobile-only' style={{ width: 40, height: 40, marginRight: 5 }} />
                    <h1 className='mobile-only mobile-title' style={{ color: '#75aafa', textAlign: 'right', fontSize: 40, marginBottom: 0, marginTop: 10, justifyContent: 'center' }}>Relampago</h1>
                </div>
                <div className='home-container top-home' style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingLeft: 50, alignItems: 'flex-start' }}>
                    <div className='only-mobile-hidden-temp mobile-hidden-temp' style={{ display: 'flex', flexDirection: 'column', marginLeft: 50, alignItems: 'flex-start', justifyContent: 'flex-start', backgroundColor: '' }}>
                        <Logo className='logo' style={{ width: 150, height: 150, marginTop: 30, marginBottom: 30 }} />
                        <h1 className='header-landing' style={{ color: '#75aafa', textAlign: 'left' }}>Relampago</h1>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <h1 className='title-landing' style={{ color: BLACK_COLOR, textAlign: 'left', marginRight: 10, marginBottom: 3 }}>{lang === 'es' ? 'Pagos del 1% a Colombia' : '1% Payments to Colombia'}</h1>
                            <img alt='colombia-1' className='flag' src={Colombia} />
                        </div>
                        <h1 className='title-landing' style={{ color: BLACK_COLOR, textAlign: 'left', marginRight: 10, marginBottom: 10 }}>{lang === 'es' ? 'Al Instante a Nequi' : 'Instant to Nequi, Bancocolombia, and Much More'}</h1>
                        <h1 className='subtitle-landing' style={{ color: BLACK_COLOR, textAlign: 'left', marginRight: 10, marginBottom: 5 }}>
                            {lang === 'es' ?
                                'Disfrute de las mejores tasas de cambio a Colombia. Con unos pocos toques, realice un pago al instante y vea cómo llega a la cuenta bancaria de su destinatario en cuestión de segundos.' :
                                'Enjoy the cheapest, fastest, and most reliable payments to Colombia. With a few taps, make a payment instantly and watch as it hits your receiver\'s account in seconds.'}
                        </h1>
                        <div className='special-shadow promo' style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Money style={{ minWidth: 80, minHeight: 80, maxHeight: 80, maxWidth: 80, marginLeft: 20 }} />
                            <h1 className='subtitle-landing' style={{ color: BLACK_COLOR, textAlign: 'left', marginRight: 10, marginLeft: 20, marginBottom: 0 }}>
                                {lang === 'es' ?
                                    'Reciba un bono de $25 cuando envíe su primer pago.' :
                                    'Receive a $25 bonus when you send your first payment.'
                                }
                            </h1>
                        </div>
                    </div>
                    <div className='video-transfer-container' style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                        <h1 className='mb-2 title-landing mobile-only' style={{ color: BLACK_COLOR, textAlign: 'left', marginBottom: 5, marginRight: 0 }}>{lang === 'es' ? 'Pagos del 1% a Nequi' : '1% Payments to Nequi'}</h1>
                        <h1 className='subtitle-landing mobile-only' style={{ color: BLACK_COLOR, textAlign: 'center', marginRight: 0, marginBottom: 0, paddingRight: 30, paddingLeft: 30 }}>
                            {lang === 'es' ?
                                'Disfrute de las mejores tasas de cambio a Colombia. Con unos pocos toques, realice un pago al instante y vea cómo llega a la cuenta bancaria de su destinatario en cuestión de segundos.' :
                                'Enjoy the cheapest, fastest, and most reliable payments to Colombia. With a few taps, make a payment instantly and watch as it hits your receiver\'s bank account in seconds.'}
                        </h1>
                        <div className='special-shadow promo mobile-only' style={{ width: 305, maxHeight: 90, marginBottom: 10, marginTop: 10, backgroundColor: 'white', flexDirection: 'row', alignItems: 'center' }}>
                            <Money style={{ maxHeight: 90, maxWidth: 90, minHeight: 40, minWidth: 40, marginLeft: 10 }} />
                            <h1 className='subtitle-landing' style={{ color: BLACK_COLOR, textAlign: 'left', marginRight: 10, marginLeft: 20, marginBottom: 0 }}>
                                {lang === 'es' ?
                                    'Reciba un bono de $25 cuando envíe su primer pago.' :
                                    'Receive a $25 bonus when you send your first payment.'
                                }
                            </h1>
                        </div>
                        <div className='mobile-only' style={{ display: 'flex', flexDirection: 'row', marginLeft: 15, marginBottom: 20 }}>
                            <Link to={`/download?origin=${origin}&override=android`}>
                                <img alt='google-play' className='mobile-only' src={GoogleBadge} style={{ width: 150, height: 50, cursor: 'pointer' }} />
                            </Link>
                            <Link to={`/download?origin=${origin}&override=ios`}>
                                <AppleDownload alt='apple-store' className='mobile-only apple-store' style={{ width: 183, height: 50, cursor: 'pointer', marginLeft: -10 }} />
                            </Link>
                        </div>
                        <div className='qr-container mobile-hidden-temp' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 0, border: '1px solid white', padding: 10, borderRadius: 15, marginBottom: 20 }}>
                            <img alt='qr-1' style={{ borderRadius: 10, maxWidth: 80, maxHeight: 80 }} src={Qr}></img>
                            <div style={{ marginLeft: 20, marginRight: 10 }}>
                                <h1 className='download-now' style={{ color: 'white', textAlign: 'center', marginRight: 0, marginTop: 0, marginBottom: 5 }}>{lang === 'es' ? 'Descargue' : 'Download'}</h1>
                                <h1 className='download-now' style={{ color: 'white', textAlign: 'center', marginRight: 0, marginTop: 0, marginBottom: 0 }}>{lang === 'es' ? 'Relampago' : 'Relampago'}</h1>
                            </div>
                        </div>
                        <div className='video-container' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Frame style={{ minHeight: 498, maxHeight: 498 }} />
                            <div style={{ width: '100%', height: '100%', backgroundColor: 'transparent', position: 'absolute', top: -3, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <video style={{ minHeight: 465, maxHeight: 465, borderRadius: '25px' }} autoPlay muted loop playsInline>
                                    <source src={vid} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='home-container tiles-outer-container' style={{ flexDirection: 'column', width: '100%', flexGrow: 1, alignItems: 'center', paddingLeft: 0, justifyContent: 'center' }}>
                    <h1 className='title-landing' style={{ color: BLACK_COLOR, textAlign: 'left', fontWeight: '700', fontSize: 50, marginRight: 10, marginTop: 100, marginBottom: 100 }}>{lang === 'es' ? '1%, al instante, y seguro' : 'Cheap, Instant, Secure'}</h1>
                    <div className='tile-container' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                        <div className='tile-wrapper'>
                            <img alt='rocket' src={Rocket} style={{ position: 'absolute', }} className='fun-tiles' />
                            <p style={{ color: 'white', position: 'absolute', zIndex: 10, }} className='tile-heading-1'>{lang === 'es' ? 'Al instante' : 'Instant'}</p>
                            <p style={{ color: 'white', position: 'absolute', zIndex: 10 }} className='tile-heading-3'>{lang === 'es' ? 'Utalizamos technologia innovadora para finalizar al instante' : 'We utilize innovative technology to achieve instant transactions'}</p>
                        </div>
                        <div className='tile-wrapper' style={{}}>
                            <img alt='earth' src={Earth} style={{ position: 'absolute', }} className='fun-tiles' />
                            <p style={{ color: 'white', position: 'absolute', zIndex: 10, }} className='tile-heading-2'>{lang === 'es' ? '1% cobro' : 'Lowest fees'}</p>
                            <p style={{ color: 'white', position: 'absolute', zIndex: 10, }} className='tile-heading-4'>{lang === 'es' ? '1% para enviar, desde $1' : 'The cheapest to send, no matter how large small the payment'}</p>
                        </div>
                        <div className='tile-wrapper' style={{}}>
                            <img alt='vault' src={Vault} style={{ position: 'absolute', }} className='fun-tiles' />
                            <p style={{ color: 'white', position: 'absolute', zIndex: 1 }} className='tile-heading-1'>{lang === 'es' ? 'Seguro' : 'Secure'}</p>
                            <p style={{ color: 'white', position: 'absolute', zIndex: 10, }} className='tile-heading-3'>{lang === 'es' ? 'Relampago conforme a la superintendencia de Colombia' : 'Relampago is regulated with local and foreign entities'}</p>
                        </div>
                        <div className='tile-wrapper disappearing-tile' style={{}}>
                            <img alt='shield' src={Shield} style={{ position: 'absolute', }} className='fun-tiles' />
                            <p style={{ color: 'white', position: 'absolute', zIndex: 10, }} className='tile-heading-2'>{lang === 'es' ? 'Sin SSN' : 'SSN Optional'}</p>
                            <p style={{ color: 'white', position: 'absolute', zIndex: 10, }} className='tile-heading-4'>{lang === 'es' ? 'No se require un SSN para utilizar Relampago, solo ITIN o una visa y cuenta bancaria' : 'A social security number is not needed to use Relampago, simply an ITIN or visa and bank account.'}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='banner-outer-container' style={{ backgroundColor: '#ddd', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <h1 className='banner-title' style={{ color: GREY_COLOR }}>{lang === 'es' ? 'Integrado Con' : 'Integrated With'}</h1>
                <div className='banner-container' style={{ marginBottom: 50, backgroundColor: '#ddd', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                    <img alt='plaid' src={mobile ? PlaidMobile : Plaid} className='banner-item' />
                    <img alt='bancocolombia' src={mobile ? BancoColombiaMobile : BancoColombia} className='banner-item bancocolombia' />
                    <img alt='nequi' src={mobile ? NequiMobile : Nequi} className='banner-item' />
                </div>
            </div>
            <div className='home-container' style={{ display: 'flex', flexDirection: 'row', width: '100%', maxWidth: 1200, justifyContent: 'space-between', alignItems: 'center', flexGrow: 1, }}>
                <div className='only-mobile-hidden-temp mobile-only' style={{ flexDirection: 'column', marginTop: 50, paddingRight: 10, paddingLeft: 10, alignItems: 'center', justifyContent: 'flex-start', backgroundColor: '' }}>
                    <h1 className='title-landing' style={{ color: BLACK_COLOR, textAlign: 'center', marginRight: 0, marginBottom: 10 }}>{lang === 'es' ? 'Pagar en menos de 5 minutos' : 'Pay in under 5 minutes'}</h1>
                    <h1 className='mb-1 subtitle-landing' style={{ color: BLACK_COLOR, textAlign: 'center', marginBottom: 0, paddingRight: 10, paddingLeft: 10 }}>
                        {lang === 'es' ?
                            'Registrarse en Relampago es tan fácil como unos pocos toques. Una vez inscrito, puede invitar a su destinatario a inscribirse o simplemente introducir sus datos manualmente. Verificaremos todo por usted.' :
                            "Signing up on Relampago is as easy as a few taps. Once signed up, you can invite your recipient to sign up, or just enter their details manually. We'll verify everything for you."
                        }
                    </h1>
                </div>
                <div className='video-transfer-container' style={{ flexGrow: 1, display: 'flex', alignItems: 'center', maxWidth: 500, overflow: 'hidden' }}>
                    <div className='video-container' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Frame style={{ minHeight: 498, maxHeight: 498 }} />
                        <div style={{ width: '100%', height: '100%', backgroundColor: 'transparent', position: 'absolute', top: -3, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={mobile ? SenderScreenshotMobile : SenderScreenshot} alt="senderscreenshot" style={{ minHeight: 465, maxHeight: 465, borderRadius: '25px' }}></img>
                        </div>
                    </div>
                </div>
                <div className='only-mobile-hidden-temp mobile-hidden-temp' style={{ display: 'flex', flexDirection: 'column', marginLeft: 50, alignItems: 'flex-start', justifyContent: 'flex-start', backgroundColor: '' }}>
                    <h1 className='title-landing' style={{ color: BLACK_COLOR, textAlign: 'left', marginRight: 10, marginBottom: 10 }}>{lang === 'es' ? 'Pagar en menos de 5 minutos' : 'Pay in under 5 minutes.'}</h1>
                    <h1 className='mb-1 subtitle-landing' style={{ color: BLACK_COLOR, textAlign: 'left', marginRight: 10, marginBottom: 0 }}>
                        {lang === 'es' ?
                            'Registrarse en Relampago es tan fácil como unos pocos toques. Una vez inscrito, puede invitar a su destinatario a inscribirse o simplemente introducir sus datos manualmente. Verificaremos todo por usted.' :
                            "Signing up on Relampago is as easy as a few taps. Once signed up, you can invite your recipient to sign up, or just enter their details manually. We'll verify everything for you."
                        }
                    </h1>
                </div>
            </div>
            <div className='home-container' style={{ display: 'flex', flexDirection: 'row', width: '100%', maxWidth: 1200, justifyContent: 'space-between', alignItems: 'center', flexGrow: 1 }}>
                <div className='only-mobile-hidden-temp mobile-only' style={{ flexDirection: 'column', marginLeft: 50, alignItems: 'center', justifyContent: 'flex-start', backgroundColor: '' }}>
                    <h1 className='title-landing' style={{ color: BLACK_COLOR, }}>{lang === 'es' ? 'Mercantes:' : 'Merchants:'}</h1>
                    <h1 className='title-landing' style={{ color: BLACK_COLOR }}>{lang === 'es' ? 'Aceptar pagos con Cash App' : 'Accept Cash App payments'}</h1>
                    <h1 className='mb-1 subtitle-landing' style={{ color: BLACK_COLOR, textAlign: 'center', marginRight: 10, marginBottom: 0 }}>
                        {lang === 'es' ?
                            'Si desea recibir pagos en persona, puede generar un QR de Cash App desde su aplicación Relampago. Entonces podrá recibir el pago sin que el pagador necesite tener Relampago para nada, directamente en Nequi o en cualquier otra cuenta bancaria que tenga.' :
                            "If you wish to receive paymenst in-person, you can generate a Cash App QR from your Relampago app. You can then be paid without the payer needing to have Relampago at all, straight into Nequi or any other bank account you have."
                        }
                    </h1>
                </div>
                <div className='only-mobile-hidden-temp mobile-hidden-temp' style={{ display: 'flex', flexDirection: 'column', marginLeft: 50, alignItems: 'flex-start', justifyContent: 'flex-start', backgroundColor: '' }}>
                    <h1 className='title-landing' style={{ color: BLACK_COLOR, marginBottom: 10 }}>{lang === 'es' ? 'Mercantes: Aceptar pagos con Cash App.' : 'Merchants: Accept Cash App payments.'}</h1>
                    <h1 className='mb-1 subtitle-landing' style={{ color: BLACK_COLOR, textAlign: 'left', marginRight: 10, marginBottom: 0 }}>
                        {lang === 'es' ?
                            'Si desea recibir pagos en persona, puede generar un QR de Cash App desde su aplicación Relampago. Entonces podrá recibir el pago sin que el pagador necesite tener Relampago para nada, directamente en Nequi o en cualquier otra cuenta bancaria que tenga.' :
                            "If you wish to receive paymenst in-person, you can generate a Cash App QR from your Relampago app. You can then be paid without the payer needing to have Relampago at all, straight into Nequi or any other bank account you have."
                        }
                    </h1>
                </div>
                <div className='video-transfer-container' style={{ flexGrow: 1, display: 'flex', alignItems: 'center', maxWidth: 500, overflow: 'hidden' }}>
                    <div className='video-container' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Frame style={{ minHeight: 498, maxHeight: 498 }} />
                        <div style={{ width: '100%', height: '100%', backgroundColor: 'transparent', position: 'absolute', top: -3, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={mobile ? CashappScreenshotMobile : CashappScreenshot} alt="CashappScreenshot" style={{ minHeight: 465, maxHeight: 465, borderRadius: '25px' }}></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className='home-container' style={{ display: 'flex', flexDirection: 'row', width: '100%', maxWidth: 1200, justifyContent: 'space-between', alignItems: 'center', flexGrow: 1 }}>
                <div className='only-mobile-hidden-temp mobile-only' style={{ flexDirection: 'column', marginLeft: 0, marginBottom: 10, alignItems: 'center', justifyContent: 'flex-start', backgroundColor: '', paddingRight: 0 }}>
                    <h1 className='title-landing' style={{ color: BLACK_COLOR, textAlign: 'center', marginRight: 10, marginBottom: 10 }}>{lang === 'es' ? 'Contactarnos' : 'Contact Us'}</h1>
                    <h1 className='mb-1 subtitle-landing' style={{ color: BLACK_COLOR, textAlign: 'center', marginBottom: 0, paddingRight: 20, paddingLeft: 20 }}>
                        {lang === 'es' ?
                            'Para asistencia, estaremos encantados de ayudarte con cualquier pregunta.' :
                            "If you have any questions, we are happy to help."
                        }
                    </h1>
                </div>
                <div id='contactus' className='video-transfer-container get-help' style={{ flexGrow: 1, display: 'flex', alignItems: 'flex-start', paddingRight: 40, paddingLeft: 40, minWidth: 400, maxWidth: 500, overflow: 'hidden' }}>
                    <a href="https://wa.me/+16064541111" style={{ textDecoration: 'none', width: '100%', }}>
                        <div className='video-container' style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 50, width: '100%' }}>
                            <p className='contact-item' style={{ marginBottom: 0, color: 'white' }}>{'+1 (606) 454-1111'}</p>
                            <Whatsapp className='contact-item-icon' style={{ width: 40, height: 40 }} />
                        </div>
                    </a>
                    <a href="https://www.instagram.com/relampagocash/" style={{ textDecoration: 'none', width: '100%', }}>
                        <div className='video-container' style={{ position: 'relative', display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 50, cursor: 'pointer' }}>
                            <p className='contact-item' style={{ marginBottom: 0, color: 'white' }}>{'@relampagocash'}</p>
                            <Instagram className='contact-item-icon' style={{ width: 40, height: 40 }} />
                        </div>
                    </a>
                    <a href="mailto:hola@relampago.cash" style={{ textDecoration: 'none', width: '100%', }}>
                        <div className='video-container' style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: 50, cursor: 'pointer' }}>
                            <p className='contact-item' style={{ marginBottom: 0, color: 'white', fontSize: 25 }}>hola@relampago.cash</p>
                            <Email className='contact-item-icon' style={{ width: 40, height: 40, }} />
                        </div>
                    </a>
                </div>
                <div className='only-mobile-hidden-temp mobile-hidden-temp' style={{ display: 'flex', flexDirection: 'column', marginLeft: 50, alignItems: 'fleax-strt', justifyContent: 'space-beetween', backgroundColor: '' }}>
                    <h1 className='title-landing' style={{ color: BLACK_COLOR, textAlign: 'left', marginRight: 10, marginBottom: 10 }}>{lang === 'es' ? 'Contactarnos' : 'Contact Us'}</h1>
                    <h1 className='mb-1 subtitle-landing' style={{ color: BLACK_COLOR, textAlign: 'left', marginRight: 10 }}>
                        {lang === 'es' ?
                            'Para asistencia, estaremos encantados de ayudarte con cualquier pregunta.' :
                            "If you have any questions, we are happy to help."
                        }
                    </h1>
                </div>
            </div>
            <div style={{ width: '100%', height: 50, backgroundColor: '#75aafa', display: 'flex', alignItems: 'center', padding: 20, justifyContent: 'center' }}>
                <p style={{ color: 'white', marginBottom: 0 }}>© Relampago 2023</p>
            </div>
        </>
    )
}