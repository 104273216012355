import React from 'react';

const API = () => {
    function redirectToAppStore() {
        window.location.href = "https://relampago.gitbook.io/relampago-apis";
    }

    const runItems = async () => {
        redirectToAppStore();
    }

    React.useEffect(() => {
        runItems();
    });

    return (<div></div>
    );
}

export default API;