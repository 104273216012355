import React from 'react';
import { delay } from './App';

const android = "https://play.google.com/store/apps/details?id=com.cash.relampago";
const ios = "https://apps.apple.com/us/app/rel%C3%A1mpago/id6449205587";

const Download = () => {

    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    const override = params["override"] || "";

    function redirectToAppStore() {
        if (override) {
            window.location.href = override === "ios" ? ios : android;
            return;
        }

        const fallbackUrl = 'https://relampago.cash';

        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Detect iOS devices
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.href = ios;
            return;
        }

        window.location.href = android;

        // Set up a timeout to check whether the redirection succeeded
        const timeout = setTimeout(() => {
            // If the custom scheme URL is not valid, the user will still be on the same page
            // Redirect to the fallback URL
            window.location = fallbackUrl;
        }, 1000);

        // Clear the timeout if the user navigates away from the page
        window.addEventListener('blur', () => clearTimeout(timeout));
    }

    const runItems = async () => {
        await delay(500);
        // Analytics.record({ name: 'relampago_download_visit', attributes: { origin } });
        redirectToAppStore();
    }

    React.useEffect(() => {
        runItems();
    });

    return (<div></div>
    );
}

export default Download;